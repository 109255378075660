import React, { useEffect, useRef } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import {
  getCityListRequest,
  getCountyListRequest,
  getStateListRequest,
} from "../../../redux/admin/location/action";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { getValueByKey } from "../../../utils/authUtils";
import { PdfSvg, ResetSvg } from "../../../components/CommonSvg";
import { isImage } from "../../../utils/generalUtils";
import { assignVendorRequest } from "../../../redux/admin/vendor/action";

const AssignVendor = (props) => {
  const {
    GetCityList,
    GetStateList,
    GetCountyList,
    CityList,
    StateList,
    CountyList,
    isAssigned,
    vendorId,
    promationData,
    assignVendorRequest,
    AssignVendorId,
    onModalDismiss,
    editPromationData,
    setEditPromationData,
    dispatchData,
  } = props;
  const AssignPromoInitialState = (values, AssignVendorId) => {
    return {
      cityId:
        values?.cityId?.length > 0 ? values.cityId.map((x) => `${x}`) : [],
      stateId:
        values?.stateId?.length > 0 ? values.stateId.map((x) => `${x}`) : [],
      countyId:
        values?.countyId?.length > 0 ? values.countyId.map((x) => `${x}`) : [],
      mailingZip:
        values?.mailingZip?.length > 0
          ? values?.mailingZip?.map((x) => `${x}`)
          : [],
    };
  };

  const AssignPromoData = AssignPromoInitialState(
    editPromationData[0],
    AssignVendorId
  );
  
  const validator = useRef(new SimpleReactValidator());
  let clipboardData = getValueByKey("AssignPromoCopy");

  useEffect(() => {
    GetCityList();
    GetStateList();
    GetCountyList();
  }, []);

  const cityOptions = CityList?.map((city) => ({
    value: city.id,
    label: city.cityName,
  }));
  const stateListOptions =
    StateList?.map((city) => ({
      value: city.id,
      label: city.stateName,
    })) || [];
  const stateOptions = [
    {
      value: "All",
      label: "All",
    },
    ...stateListOptions,
  ];

  const countyOptions = CountyList?.map((city) => ({
    value: city.id,
    label: city.countyName,
  }));

  const isAtLeastOneFieldFilled = (values) => {
    return (
      values.stateId.length > 0 ||
      values.cityId.length > 0 ||
      values.countyId.length > 0 ||
      values.mailingZip.length > 0
    );
  };

  const handleCopyAllFields = async (formValues) => {
    const combinedValues = {
      stateId: formValues.stateId,
      cityId: formValues.cityId,
      countyId: formValues.countyId,
      mailingZip: formValues.mailingZip,
    };
    const combinedValuesString = JSON.stringify(combinedValues);
    try {
      localStorage.setItem("AssignPromoCopy", combinedValuesString);
      ApiResponseMessage("All fields have been copied successfully.", SUCCESS);
      document.execCommand("copy");
    } catch (err) {
      ApiResponseMessage("Error copying text: " + err.message);
    }
  };
  const handlePasteFields = (setFieldValue) => {
    clipboardData = getValueByKey("AssignPromoCopy");
    if (!clipboardData) {
      alert("Clipboard API is not supported in this browser.");
      return;
    }
    try {
      const parsedData = JSON.parse(clipboardData);
      if (
        parsedData &&
        parsedData.stateId &&
        parsedData.cityId &&
        parsedData.countyId &&
        parsedData.mailingZip
      ) {
        setFieldValue("stateId", parsedData.stateId);
        setFieldValue("cityId", parsedData.cityId);
        setFieldValue("countyId", parsedData.countyId);
        setFieldValue("mailingZip", parsedData.mailingZip);
        ApiResponseMessage(
          "All fields have been successfully pasted.",
          SUCCESS
        );
      } else {
        alert("Clipboard data structure does not match the expected fields.");
      }
    } catch (error) {
      alert("Error pasting fields. Please make sure the copied data is valid.");
    }
  };

  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {

      if (isAssigned === true && isAtLeastOneFieldFilled(values)) {

        const AssignedValues = {
          ...values,


          mailingZip:
            values?.mailingZip?.length > 0 ? [values?.mailingZip] : [],
          vendorId: editPromationData[0].vendorId,
          vendorAllotedId: editPromationData[0].id,
        };
        setEditPromationData([]);
        assignVendorRequest({ AssignedValues, dispatchData });
        onModalDismiss();
      } else {
        if (isAssigned !== true && isAtLeastOneFieldFilled(values)) {
          
          const AssignedValues = {
            vendorId: AssignVendorId,
            mailingZip:
              values?.mailingZip?.length > 0 ? [values?.mailingZip] : [],
            stateId: values?.stateId?.length > 0 ? values?.stateId : [],
            cityId: values?.cityId?.length > 0 ? values?.cityId : [],
            countyId: values?.countyId?.length > 0 ? values?.countyId : [],
          };
          onModalDismiss();
          setEditPromationData([]);
          assignVendorRequest({ AssignedValues, dispatchData });
        } else {
          ApiResponseMessage(
            __t("validations.promotion.selectOnlyOne"),
            DANGER
          );
        }
      }
    } else {
      validator.current.showMessages();
    }
  };
  return (
    <>
      <Row>
        {AssignVendorId.length > 1 && (
          <Col lg={6}>
            <h5 className="assign_modal_title">Vendor</h5>
            <ul className="assign_modal_img small-scroll">
              {promationData.map((d) => (
                <li key={d.id}>
                  {isImage(d.imgUrl) ? (
                    <img alt="Logo" className="logo_main" src={d.imgUrl} />
                  ) : (
                    <span className="docfile">
                      <PdfSvg />
                    </span>
                  )}
                  <div className={"assign_modal_content"}>
                    <span className="fw-bold">{d.title}</span>
                    <span>{d.description}</span>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        )}
        <Col lg={AssignVendorId.length > 1 && 6}>
          <Formik initialValues={AssignPromoData} enableReinitialize={true} onSubmit={handleSubmit}>
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="form-horizontal form-login row">
                <FormGroup className="col-12">
                  <Field
                    component={Select}
                    menuPlacement="auto"
                    name="stateId"
                    className="form-control selectbox"
                    options={stateOptions}
                    isClearable={true}
                    isMulti={true}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("stateId", selectedValues);
                    }}
                    value={stateOptions?.filter((option) =>
                      values.stateId?.includes(typeof option?.value === 'string' ? option?.value :  JSON.stringify(option?.value))
                    )}
                  />

                  <Label className="form-label" htmlFor="stateId">
                    State
                  </Label>
                </FormGroup>
                {values?.stateId?.[0] !== "All" && (
                  <>
                    <FormGroup className="col-12">
                      <Field
                        type="text"
                        id="cityId"
                        name="cityId"
                        className="form-control selectbox"
                        component={Select}
                        isMulti
                        menuPlacement="auto"
                        options={cityOptions}
                        value={cityOptions?.filter((option) =>
                          values.cityId?.includes(typeof option?.value === 'string' ? option?.value :  JSON.stringify(option?.value))
                        )}
                        onChange={(selectedOptions) => {
                          setFieldValue("cityId",selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                      <Label className="form-label" htmlFor="cityId">
                        City
                      </Label>
                    </FormGroup>
                    <FormGroup className="col-12">
                      <Field
                        type="text"
                        id="countyId"
                        name="countyId"
                        className="form-control selectbox"
                        component={Select}
                        isMulti
                        menuPlacement="auto"
                        options={countyOptions}
                        value={countyOptions?.filter((option) =>
                          values.countyId?.includes(typeof option?.value === 'string' ? option?.value :  JSON.stringify(option?.value))
                        )}
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "countyId",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                        disabled={values?.stateId?.[0] === "All"}
                      />
                      <Label className="form-label" htmlFor="countyId">
                        County
                      </Label>
                    </FormGroup>
                    <FormGroup className="col-12">
                      <Field
                        id="mailingZip"
                        name="mailingZip"
                        className="form-control"
                        type="text"
                      />
                      <Label
                        className="form-label sticky_lable"
                        htmlFor="mailingZip"
                      >
                        Mailing zip
                      </Label>
                      <span className="form-text text-muted text-end w-100 d-inline-block">
                        Multiple mailing zip can be added using comma (",")
                      </span>
                    </FormGroup>
                  </>
                )}
                {(editPromationData[0]?.createdBy === getValueByKey("user") ||
                  JSON.parse(getValueByKey("roleId")) === "1" ||
                  editPromationData.length === 0) && (
                  <>
                  {(isAtLeastOneFieldFilled(values) ||clipboardData !== null||AssignVendorId.length > 1)&&   <div className="btn-box assign_btn">
                      {isAtLeastOneFieldFilled(values) && (
                        <Button
                          color="secondary"
                          outline
                          className="mx-2 copy-button"
                          onClick={() => handleCopyAllFields(values)}
                        >
                          Copy All Fields
                        </Button>
                      )}
                      {clipboardData !== null && (
                        <Button
                          color="info"
                          outline
                          className="mx-2 paste-button"
                          onClick={() => handlePasteFields(setFieldValue)}
                        >
                          Paste Fields
                        </Button>
                      )}
                      {AssignVendorId.length > 1
                        ? null
                        : editPromationData[0] && (
                          <Button
                            color="danger"
                            outline
                            className="mx-2"
                            onClick={() => {
                              const AssignedValues = {
                                unAssignVendor: true,
                                vendorId: editPromationData[0].vendorId,
                                vendorAllotedId: editPromationData[0].id,
                              };
                              setEditPromationData([]);
                              assignVendorRequest({
                                AssignedValues,
                                dispatchData,
                              });
                              onModalDismiss();
                            }}
                          >
                            <ResetSvg /> Reset assignment
                          </Button>
                        )}
                    </div>}
                    </>
                  )}
                <Button
                  color="primary"
                  block
                  type="submit"
                  className="justify-content-center"
                  disabled={isSubmitting}
                >
                  Submit assignment
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = ({ location }) => {
  let CityList;
  let StateList;
  let CountyList;
  if (location.getCity && Array.isArray(location.getCity.list) && location.getCity.list.length > 0) {
    CityList = location.getCity.list;
  }

  if (location.getState && Array.isArray(location.getState.list) && location.getState.list.length > 0) {
    StateList = location.getState.list;
  }

  if (location.getCounty && Array.isArray(location.getCounty.list) && location.getCounty.list.length > 0) {
    CountyList = location.getCounty.list;
  }

  return {
    CityList: CityList,
    StateList: StateList,
    CountyList: CountyList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetCityList: () => {
      dispatch(getCityListRequest());
    },
    GetStateList: () => {
      dispatch(getStateListRequest());
    },
    GetCountyList: () => {
      dispatch(getCountyListRequest());
    },
    assignVendorRequest: (data) => {
      dispatch(assignVendorRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignVendor);
